<template>
  <div class="content">
    <h1 class="title title--big title--theme title--indent">
      Командировки
    </h1>
    <div class="trips">
      <form class="form trips__form" @submit.prevent="onRequestTrips">
        <div class="form__content form__content--end">
          <div class="form-group form-group--nom">
            <label for="date_start">Период, с</label>
            <div class="form-control">
              <masked-input
                v-model="date_start"
                name="date_start"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_start"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-group form-group--nom">
            <label for="date_end">Период, по</label>
            <div class="form-control">
              <masked-input
                v-model="date_end"
                name="date_start"
                mask="11.11.1111"
                placeholder="дд.мм.гггг"
                class="form-control__input"
                id="date_end"
                autocomplete="off"
              />
            </div>
          </div>
          <button
            ref="submit"
            class="button button--mini"
            type="submit"
            :disabled="!datesCorrect"
          >
            <span>Применить</span>
            <img src="@/assets/img/preloader.svg" alt="" class="button__preloader" />
          </button>
        </div>
      </form>
      <div
        v-for="(trip, index) in trips"
        :key="trip.counterparty_id + index"
        class="trips__item"
      >
        <div class="trips__top">
          <span class="trips__name">{{ trip.counterparty_name }}</span>
          <span class="trips__date">{{ formatDateToShow(trip.visit_date) }}</span>
        </div>
        <div class="trips__info">
          <div class="trips__key-value">
            <span>Менеджер: </span>
            <span>{{ trip.manager_name }}</span>
          </div>
        </div>
        <div class="trips__info">
          <div class="trips__key-value">
            <span>Цель: </span>
            <span>{{ trip.goal }}</span>
          </div>
        </div>
        <div class="trips__desc">
          <div class="trips__key-value">
            <span>Результат: </span>
            <span>{{ trip.result }}</span>
          </div>
        </div>
        <div v-if="trip.images.length" class="trips__gallery">
          <button
            class="trips__image"
            v-for="(image, imageIndex) in trip.images"
            :key="imageIndex"
            :style="{ backgroundImage: 'url(' + image + ')'}"
          >
            <img v-img="{group: trip.counterparty_id + index}" :src="image" alt="Trip photo">
          </button>
        </div>
      </div>
      <div class="trips__empty" v-if="!trips.length">
        <img src="@/assets/img/empty-trips.svg" alt="no trips">
        <span>В выбранный период командировки отстутствуют.</span>
      </div>
    </div>
  </div>
</template>

<script>
import MaskedInput from 'vue-masked-input'
import { lightFormat, formatISO, parse, format } from 'date-fns'
import ru from 'date-fns/locale/ru'

export default {
  name: 'Trips',
  components: {
    MaskedInput,
  },
  data() {
    return {
      trips: [],
      date_start: '',
      date_end: '',
    }
  },
  computed: {
    datesCorrect() {
      return this.date_start.replaceAll('_', '').length === 10 && this.date_end.replaceAll('_', '').length === 10
    },
  },
  mounted() {
    const date = formatISO(new Date()).slice(0, 10)
    this.date_start = lightFormat(new Date(date), 'dd.MM.yyyy')
    this.date_end = lightFormat(new Date(date), 'dd.MM.yyyy')
    this.getTrips(date, date)
  },
  methods: {
    getTrips(from_visit_date, to_visit_date) {
      this.$refs.submit.classList.add('preload')
      this.$store.dispatch('trips/GET_LIST', { from_visit_date, to_visit_date })
        .then(response => {
          this.trips = response.data
        })
        .finally(() => {
          this.$refs.submit.classList.remove('preload')
        })
    },
    onRequestTrips() {
      this.getTrips(this.formatDateToRequest(this.date_start), this.formatDateToRequest(this.date_end))
    },
    formatDateToShow(date) {
      return format(new Date(date), 'HH:mm, dd.MM.yyyy')
    },
    formatDateToRequest(date) {
      return formatISO(parse(date, 'dd.MM.yyyy', new Date())).slice(0, 10)
    }
  }
}
</script>

<style scoped lang="sass">
@import "@/assets/common/index.scss"

.trips__form
  margin-bottom: 30px

.trips__item
  margin-bottom: 20px
  padding: 30px 20px
  border-radius: 10px
  box-shadow: 0 0 4px rgba(151, 151, 151, 0.25)
  background-color: #f8fdff

  &:last-child
    margin-bottom: 0

  +max-w($mobile_sm)
    padding: 20px 15px

.trips__top
  display: flex
  align-items: flex-end
  flex-wrap: wrap
  gap: 12px
  margin-bottom: 16px

.trips__name
  font-size: 20px
  line-height: 27px
  font-weight: 600

.trips__date
  color: #7c8db0
  font-size: 16px
  line-height: 23px

.trips__info
  display: flex
  flex-wrap: wrap
  gap: 20px 30px
  margin-bottom: 10px

.trips__key-value

  span
    font-size: 16px

    &:first-child
      color: #7c8db0

.trips__desc
  margin-bottom: 20px
  font-size: 16px

  &:last-child
    margin-bottom: 0

.trips__gallery
  display: grid
  grid-template-columns: repeat(auto-fill, 160px)
  gap: 16px

  +max-w($mobile_sm)
    grid-template-columns: repeat(2, 1fr)

.trips__image
  position: relative
  overflow: hidden
  height: 90px
  border-radius: 5px
  background-size: cover
  background-repeat: no-repeat
  background-position: center center
  transition: opacity 0.3s

  img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover

    &:before
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-image: url('../assets/img/img-thumb.svg')
      background-size: cover
      background-position: center

  &:active
    opacity: 0.8

  @media (hover: hover)
    &:hover,
    &:focus
      opacity: 0.8

.trips__empty
  display: flex
  flex-direction: column
  align-items: center
  gap: 20px
  margin-top: 100px
  text-align: center
  font-size: 20px
  font-weight: 600
</style>