import Vue from 'vue'
import vSelect from 'vue-select'
import Paginate from 'vuejs-paginate'
import Notifications from 'vue-notification'
import VueTables from 'vue-tables-2'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import VueImg from 'v-img'

import DefaultLayout from './layouts/Default'
import WelcomeLayout from './layouts/Welcome'

Vue.component('default', DefaultLayout)
Vue.component('welcome', WelcomeLayout)
Vue.component('v-select', vSelect)
Vue.component('paginate', Paginate)
Vue.component('v-popover', VPopover)

Vue.directive('tooltip', VTooltip.VTooltip)
Vue.directive('close-popover', VClosePopover)

Vue.use(Notifications)
Vue.use(VueTables.ClientTable)
Vue.use(VueImg)
