<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Склады</h1>
    </div>
    <div class="table-input table-input--5">
      <div v-if="loading" class="loading loading--content" id="loading">
        <div class="loading__body">
          <div class="effect-1 loading__effects"></div>
          <div class="effect-2 loading__effects"></div>
          <div class="effect-3 loading__effects"></div>
        </div>
      </div>
      <div v-if="!loading" class="table-input__wrapper">
        <div class="table-input__header">
          <div class="table-input__row">
            <div class="table-input__col">
              <div class="table-input__title">Код</div>
            </div>
            <div class="table-input__col">
              <div class="table-input__title">Город</div>
            </div>
            <div class="table-input__col">
              <div class="table-input__title">Тип</div>
            </div>
            <div class="table-input__col">
              <div class="table-input__title">Название 1С</div>
            </div>
            <div class="table-input__col">
              <div class="table-input__title">Дополнительное название</div>
            </div>
          </div>
        </div>
        <div class="table-input__body">
          <div class="table-input__row" v-for="item in warehouses" :key="item.code_1c">
            <div class="table-input__col">
              <div class="table-input__text">{{ item.code_1c }}</div>
            </div>
            <div class="table-input__col">
              <div class="table-input__text">{{ item.city_name }}</div>
            </div>
            <div class="table-input__col">
              <div class="table-input__text">{{ item.warehouse_type === 'WS' ? 'Оптовый' : 'Розничный' }}</div>
            </div>
            <div class="table-input__col">
              <div class="table-input__text">{{ item.name_1с }}</div>
            </div>
            <div class="table-input__col">
              <input
                type="text"
                name="markup"
                :data-price-id="item.code_1c"
                :value="item.custom_name"
                @change="onMarkupChange"
                class="table-input__input"
                placeholder="Введите название"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Warehouses',
  data() {
    return {
      warehouses: {},
      loading: true
    }
  },
  mounted() {
    this.$store.dispatch('dict/GET_WAREHOUSES')
      .then(response => {
        this.warehouses = response.data
        this.loading = false
      })
  },
  methods: {
    onMarkupChange(event) {
      this.submitPriceGroup(event.target.value, event.target.getAttribute('data-price-id'))
    },
    submitPriceGroup(value, code) {
      this.$store.dispatch('dict/PATCH_WAREHOUSE', { code, data: { custom_name: value } })
        .then(res => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Название склада обновлено'
          })
        })
    }
  }
}
</script>
