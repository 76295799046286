import axios from '@/http/axios'

export default {
  namespaced: true,
  actions: {
    GET_COUNTERPARTIES({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios
          .get(`counterparties/?has_client=false&search=${search}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_COUNTERPARTIES_AVAILABLE({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`clients/${id}/counterparties/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_COUNTERPARTIES_1C({ commit }, code) {
      return new Promise((resolve, reject) => {
        axios
          .get(`counterparties_from_1c/${code}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_WAREHOUSES() {
      return new Promise((resolve, reject) => {
        axios
          .get('warehouses/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PATCH_WAREHOUSE({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`warehouses/${payload.code}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_MANAGERS({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios
          .get(`managers/?search=${search}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STATISTICS() {
      return new Promise((resolve, reject) => {
        axios
          .get('stats/total/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STATISTICS_USERS() {
      return new Promise((resolve, reject) => {
        axios
          .get('stats/total_with_users/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STATISTICS_BY_DAYS({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`stats/total_by_days/?date_begin=${payload.from}&date_end=${payload.to}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_STATISTICS_WITH_USERS() {
      return new Promise((resolve, reject) => {
        axios
          .get('stats/total_with_users/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_LOGS({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`logs/?per_page=${payload.limit}&page=${payload.page}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_LOG({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`logs/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}
