import axios from '@/http/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`clients/${payload.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_USER_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/client_users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_CATEGORIES() {
      return new Promise((resolve, reject) => {
        axios
          .get('categories/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_USER_CATEGORIES({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`client_users/${id}/categories`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_PRICES({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`clients/${id}/prices/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_MANAGERS({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`clients/${id}/managers/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('client_users/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PUT_PRICES({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`price_groups/${payload.price_group_code}/clients/${payload.client_id}/markup/`, { markup: payload.markup })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PUT_USER_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/client_users/${payload.id}`, payload.form)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_DATA(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`client_users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}
