<template>
  <div class="content">
    <div class="content__title">
      <h1 class="title title--big title--theme">Запрос в 1С</h1>
    </div>
    <div class="content__tabs">
      <div class="tabs-list">
        <ul class="tabs-list__body">
          <li class="tabs-list__item">
            <router-link
              :to="{ name: '1c-products' }"
              exact-active-class="tabs-list__link--active"
              class="tabs-list__link"
            >
              Товары
            </router-link>
          </li>
          <li class="tabs-list__item">
            <router-link
              :to="{ name: '1c-counterparties' }"
              exact-active-class="tabs-list__link--active"
              class="tabs-list__link"
            >
              Контрагенты
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'c1Layout',
}
</script>
