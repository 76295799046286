<template>
  <nav class="menu" :class="{ 'menu--hide': $store.state.sidebar && !show && !mobileShow, 'menu--show': show }">
    <div class="menu__item" v-for="item in menu" :key="item.id">
      <router-link
        :to="{ name: item.link }"
        class="menu__link"
        active-class="menu__link--active"
      >
        <img
          :src="require('@/assets/img/sidebar/' + item.icon)"
          :alt="item.link"
          class="menu__icon"
        />
        <div class="menu__text">{{ item.name }}</div>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Menu',
  props: ['show', 'mobileShow'],
  data () {
    return {
      menu: [
        {
          id: 1,
          name: 'Клиенты',
          link: 'clients-list',
          icon: 'user-icon.svg',
        },
        {
          id: 2,
          name: 'Запрос в 1С',
          link: '1c-products',
          icon: '1с.svg',
        },
        {
          id: 3,
          name: 'Заказы',
          link: 'orders-list',
          icon: 'shop-icon.svg',
        },
        {
          id: 4,
          name: 'Командировки',
          link: 'trips',
          icon: 'trip-icon.svg',
        },
        {
          id: 5,
          name: 'Пользователи',
          link: 'users-layout',
          icon: 'users-icon.svg',
        },
        {
          id: 6,
          name: 'Статистика',
          link: 'statistics',
          icon: 'stat.svg',
        },
        {
          id: 7,
          name: 'Склады',
          link: 'warehouses',
          icon: 'fluent.svg',
        },
        {
          id: 8,
          name: 'Реклама',
          link: 'commercial-list',
          icon: 'commercial.svg',
        }
      ],
    }
  },
}
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.menu
  padding-top: 10px

.menu--hide
  .menu__text
    display: none

  .menu__arrow
    display: none

    &.is-show
      display: none

  .menu__submenu.is-show
    max-height: 0

.menu--show
  .menu__text
    display: block

  .menu__arrow
    display: block

  .menu__submenu
    max-height: 150px

    &:not(.is-show)
      max-height: 0

.menu__item
  position: relative

.menu__link
  display: flex
  align-items: center
  padding: 11px 32px
  border-left: 3px solid transparent
  color: #323f4b
  text-decoration: none
  transition: color 0.3s

  &:hover
    color: $color-theme

.menu__toggler
  display: flex
  align-items: center
  padding: 11px 32px
  border-left: 3px solid transparent
  color: #323f4b
  text-decoration: none
  cursor: pointer
  transition: color 0.3s

  &:hover
    color: $color-theme

  &.is-show
    .menu__arrow
      transform: rotate(180deg)

.menu__link--active
  border-left-color: $color-theme
  background-color: #f1f8fe

  .menu__text
    color: $color-theme
    font-weight: 600

.menu__icon
  margin-right: 18px

.menu__text
  font-size: 16px
  white-space: nowrap

.menu__submenu
  max-height: 0
  padding-left: 95px
  overflow: hidden
  transition: max-height 0.3s

  &.is-show
    max-height: 165px

  li
    position: relative
    margin-bottom: 10px

    &::before
      content: ''
      position: absolute
      top: 8px
      left: -16px
      width: 7px
      height: 7px
      border-radius: 50%
      background-color: $color-theme

.menu__subitem
  font-size: 16px
  color: $color-text-base
  text-decoration: none
  cursor: pointer
  transition: color 0.3s

  &.active
    color: $color-theme

  &:hover
    color: $color-theme

.menu__subitem--active
  color: $color-theme

.menu__arrow
  position: absolute
  top: 0
  right: 0
  width: 52px
  height: 52px
  background-image: url("../../assets/img/sidebar/arrow-bottom.svg")
  background-repeat: no-repeat
  background-position: center
  background-size: 11px
  pointer-events: none
  transition: transform 0.3s

</style>
