import axios from '@/http/axios'

export default {
  namespaced: true,
  actions: {
    GET_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`clients/?per_page=${payload.limit}&page=${payload.page}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_USER({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios
          .get(`clients/?search=${search}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_USERS({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`clients/${payload.id}/users/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('clients/', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PUT_DATA({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`clients/${payload.id}`, payload.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_DATA(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`clients/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}
