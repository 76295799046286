import axios from '@/http/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST() {
      return axios.get('advertising/')
    },
    GET_DATA(store, id) {
      return axios.get(`advertising/${id}`)
    },
    POST_DATA(state, form) {
      return axios.post('advertising/', form)
    },
    PUT_DATA(state, payload) {
      return axios.put(`advertising/${payload.id}/`, payload.form)
    },
    DELETE_DATA(store, id) {
      return axios.delete(`advertising/${id}`)
    },
    PUT_IMAGE(store, payload) {
      return axios.put(`advertising_image/${payload.id}/`, payload.data)
    },
    DELETE_IMAGE(store, id) {
      return axios.delete(`advertising_image/${id}/`)
    },
  }
}
