import axios from '@/http/axios'

export default {
  namespaced: true,
  actions: {
    GET_PRODUCTS({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/item_1c/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_COUNTERPARTIES({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`counterparties_from_1c/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}
