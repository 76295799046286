import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'
import ClientsIndex from '@/views/clients/Index'
import ClientsList from '@/views/clients/List'
import c1Layout from '@/views/1c/Layout'
import OrdersIndex from '@/views/orders/Index'
import UsersIndex from '@/views/users/Index'
import UsersLayout from '@/views/users/Layout'
import StatisticsIndex from '@/views/statistics/Index'
import Warehouses from '@/views/Warehouses'
import Commercial from '@/views/commercial/Index'
import Trips from '@/views/Trips'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: { name: 'clients-list' }
  },
  {
    path: '/clients',
    component: ClientsIndex,
    children: [
      {
        path: '',
        name: 'clients-list',
        component: ClientsList,
        meta: {
          title: 'Монарх - Список клиентов',
          requiresAuth: true
        },
      },
      {
        path: 'create',
        name: 'clients-create',
        component: () => import('@/views/clients/Create.vue'),
        meta: {
          title: 'Монарх - Добавление клиента',
          requiresAuth: true
        },
      },
      {
        path: ':id',
        component: () => import('@/views/clients/client/Index'),
        children: [
          {
            path: '',
            component: () => import('@/views/clients/client/Layout'),
            children: [
              {
                path: '',
                name: 'client-detail',
                component: () => import('@/views/clients/client/Detail'),
                meta: {
                  title: 'Монарх - Общая информация',
                  requiresAuth: true
                },
              },
              {
                path: 'users',
                name: 'users-list',
                component: () => import('@/views/clients/client/Users'),
                meta: {
                  title: 'Монарх - Список пользователей',
                  requiresAuth: true
                },
              },
              {
                path: 'prices',
                name: 'prices',
                component: () => import('@/views/clients/client/Prices'),
                meta: {
                  title: 'Монарх - Цены',
                  requiresAuth: true
                },
              },
              {
                path: 'managers',
                name: 'managers',
                component: () => import('@/views/clients/client/Managers'),
                meta: {
                  title: 'Монарх - Менеджеры',
                  requiresAuth: true
                },
              },
              {
                path: 'orders',
                name: 'client-orders',
                component: () => import('@/views/clients/client/Orders'),
                meta: {
                  title: 'Монарх - Заказы',
                  requiresAuth: true
                },
              },
            ]
          },
          {
            path: 'users/create',
            name: 'user-create',
            component: () => import('@/views/clients/user/Create'),
            meta: {
              title: 'Монарх - Добавление пользователя',
              requiresAuth: true
            },
          },
          {
            path: 'users/:user',
            name: 'user-detail',
            component: () => import('@/views/clients/user/Detail'),
            meta: {
              title: 'Монарх - Пользователь',
              requiresAuth: true
            },
          },
        ]
      },
    ]
  },
  {
    path: '/1c',
    component: c1Layout,
    children: [
      {
        path: '',
        name: '1c-products',
        component: () => import('@/views/1c/Products'),
        meta: {
          title: 'Монарх - Остатки 1С Продукты',
          requiresAuth: true
        },
      },
      {
        path: 'counterparties',
        name: '1c-counterparties',
        component: () => import('@/views/1c/Counterparties.vue'),
        meta: {
          title: 'Монарх - Остатки 1С Контрагенты',
          requiresAuth: true
        },
      }
    ]
  },
  {
    path: '/orders',
    component: OrdersIndex,
    children: [
      {
        path: '',
        name: 'orders-list',
        component: () => import('@/views/orders/List'),
        meta: {
          title: 'Монарх - Заказы',
          requiresAuth: true
        },
      },
      {
        path: ':id',
        component: () => import('@/views/orders/Layout'),
        children: [
          {
            path: '',
            name: 'order-detail',
            component: () => import('@/views/orders/Detail'),
            meta: {
              title: 'Монарх - Основные данные заказа',
              requiresAuth: true
            },
          },
          {
            path: 'request-1c',
            name: 'request-1c',
            component: () => import('@/views/orders/Request1c'),
            meta: {
              title: 'Монарх - Запрос в 1С',
              requiresAuth: true
            },
          },
          {
            path: 'response-1c',
            name: 'response-1c',
            component: () => import('@/views/orders/Response1c'),
            meta: {
              title: 'Монарх - Ответ 1С',
              requiresAuth: true
            },
          }
        ]
      },
    ]
  },
  {
    path: '/users',
    component: UsersIndex,
    children: [
      {
        path: 'admins/create',
        name: 'admins-create',
        component: () => import('@/views/users/admins/Create'),
        meta: {
          title: 'Монарх - Добавление администратора'
        },
      },
      {
        path: 'managers/create',
        name: 'managers-create',
        component: () => import('@/views/users/managers/Create'),
        meta: {
          title: 'Монарх - Добавление менеджера'
        },
      },
      {
        path: 'admins/:id',
        name: 'admins-detail',
        component: () => import('@/views/users/admins/Detail'),
        meta: {
          title: 'Монарх - Редактирование администратора'
        },
      },
      {
        path: 'managers/:id',
        name: 'managers-detail',
        component: () => import('@/views/users/managers/Detail'),
        meta: {
          title: 'Монарх - Редактирование менеджера'
        },
      },
      {
        path: '',
        name: 'users-layout',
        component: UsersLayout,
        redirect: { name: 'users-admins' },
        children: [
          {
            path: 'admins',
            name: 'users-admins',
            component: () => import('@/views/users/admins/List'),
            meta: {
              title: 'Монарх - Администраторы'
            },
          },
          {
            path: 'managers',
            name: 'users-managers',
            component: () => import('@/views/users/managers/List'),
            meta: {
              title: 'Монарх - Менеджеры'
            },
          },
        ]
      },
    ]
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: StatisticsIndex,
    redirect: { name: 'stat-all' },
    children: [
      {
        path: 'all/:id',
        name: 'stat-all-detail',
        component: () => import('@/views/statistics/all/Detail'),
        meta: {
          title: 'Монарх - Описание лога'
        },
      },
      {
        path: 'unloading/:id',
        name: 'stat-unloading-detail',
        component: () => import('@/views/statistics/unloading/Detail'),
        meta: {
          title: 'Монарх - Описание лога'
        },
      },
      {
        path: '',
        name: 'stat-layout',
        component: () => import('@/views/statistics/Layout'),
        redirect: { name: 'stat-all' },
        children: [
          {
            path: 'all',
            name: 'stat-all',
            component: () => import('@/views/statistics/all/List'),
            meta: {
              title: 'Монарх - Статистика'
            },
          },
          {
            path: 'unloading',
            name: 'stat-unloading',
            component: () => import('@/views/statistics/unloading/List'),
            meta: {
              title: 'Монарх - Статистика'
            },
          },
        ]
      }
    ]
  },
  {
    path: '/warehouses',
    name: 'warehouses',
    component: Warehouses,
    meta: {
      title: 'Монарх - Склады'
    },
  },
  {
    path: '/commercial',
    component: Commercial,
    children: [
      {
        path: '',
        name: 'commercial-list',
        component: () => import('@/views/commercial/List.vue'),
        meta: {
          title: 'Монарх - Список рекламы',
          requiresAuth: true
        },
      },
      {
        path: 'create',
        name: 'commercial-create',
        component: () => import('@/views/commercial/Create.vue'),
        meta: {
          title: 'Монарх - Добавление рекламы',
          requiresAuth: true
        },
      },
      {
        path: ':id',
        name: 'commercial-detail',
        component: () => import('@/views/commercial/Detail'),
        meta: {
          title: 'Монарх - Реклама',
          requiresAuth: true
        },
      },
    ]
  },
  {
    path: '/trips',
    name: 'trips',
    component: Trips,
    meta: {
      title: 'Монарх - Командировки'
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'welcome',
      title: 'Монарх - Авторизация'
    },
  },
  {
    path: '*',
    redirect: { name: 'clients-list' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  const appLoading = document.getElementById('loading')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const loggedIn = localStorage.getItem('accessToken')

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
