import axios from '@/http/axios'

export default {
  namespaced: true,
  actions: {
    GET_LIST({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`orders/?per_page=${payload.limit}&page=${payload.page}&status=${payload.status}&search=${payload.search}&client_id=${payload.client_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`orders/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_RESEND({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`orders/resend/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}
