import axios from '@/http/axios'

export default {
  namespaced: true,
  actions: {
    GET_ADMINS() {
      return new Promise((resolve, reject) => {
        axios
          .get('admin_users/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_ADMIN({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin_users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_ADMIN({ commit }, form) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin_users/', form)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PUT_ADMIN({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`admin_users/${payload.id}/`, payload.form)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_ADMIN(store, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin_users/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_MANAGERS() {
      return new Promise((resolve, reject) => {
        axios
          .get('manager_users/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    GET_MANAGER({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`manager_users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    POST_MANAGER({ commit }, form) {
      return new Promise((resolve, reject) => {
        axios
          .post('manager_users/', form)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    PUT_MANAGER({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`manager_users/${payload.id}`, payload.form)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DELETE_MANAGER({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`manager_users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}
