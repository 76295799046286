import axios from '@/http/axios'
import router from '@/router'

export default {
  init() {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
        if (err.response.status === 401) {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          router.push({ name: 'login' }).catch(() => {})
        }
        throw err
      })
    })
  },
  login (username, password) {
    return axios.post('authorize/login', {
      username,
      password
    })
  },
  logout() {
    localStorage.clear()
    delete axios.defaults.headers.common['Authorization']
  },
  refreshToken () {
    return axios.post('authorize/refresh/', { refresh: localStorage.getItem('refreshToken') })
  }
}
