import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/http/axios'
import dict from './dict'
import auth from './auth'
import clients from './clients'
import client from './client'
import c1 from './1c'
import usersMain from './users-main'
import orders from './orders'
import commercial from './commercial'
import trips from './trips'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    sidebar: false,
    sidebarOpen: false,
    firstName: '',
    lastName: '',
  },
  mutations: {
    sidebarToggle(state) {
      state.sidebar = !state.sidebar
    },
    sidebarMobileToggle(state) {
      state.sidebarOpen = !state.sidebarOpen
    },
    setFirstName (state, payload) {
      state.firstName = payload
    },
    setLastName (state, payload) {
      state.lastName = payload
    },
  },
  actions: {
    GET_ME() {
      return new Promise((resolve, reject) => {
        axios
          .get('users/me/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
  modules: {
    dict,
    auth,
    clients,
    client,
    c1,
    usersMain,
    orders,
    commercial,
    trips,
  }
})
